import React from 'react';
// import video from 'resources/video.mp4';
import Button from '../components/Button';
import { Element } from 'react-scroll';

const FirstSection = () => {

  return (
    <Element className="labs-section-first" name="labs.dev">
      <div className="decor-1" />
      <div className="decor-2" />
      <div className="labs-section-first__content">
        <div className="labs-container">
          <div className="labs-section-first__content-title">
            <span>
              Solidity applications development &<br/>consulting
            </span>
          </div>
          {/* <Button title="Contact Us" onClick={()=>window.open('https://forms.gle/2nCCqiJfYB4kdEfZ6', '_blank')}/> */}
        </div>
        <div className="labs-section-first__content-video" style={{visibility: 'visible'}}>
            <video
                id="video-head"
                width="100%"
                height="auto"
                autoPlay
                muted
                controls={false}
                loop
                playsInline
            >
                {/* <source src={video} type="video/mp4"/> */}
                <source src="https://firebasestorage.googleapis.com/v0/b/labs-dev-web.appspot.com/o/labs.dev-black-compressed.mp4?alt=media&token=065113af-43d5-4dad-9b13-ee49e5d3577f" type="video/mp4"/>
                Your browser does not support the video tag.
            </video>
        </div>
      </div>
    </Element>
  );
};

export default FirstSection;
