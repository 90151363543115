import React from 'react';
import Logo from 'resources/images/logo.svg';


const Header = () => {

  return (
    <>
      <div className={`labs-header-wrapper`}>
        <div className="labs-container">
          <div className="labs-header">
            <div className="labs-header__logo">
              <img src={Logo} alt="LABS.DEV consults on web3 solidity dApp development on any stage until audit and launch in the mainnet." />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
