import React from 'react';
import 'resources/scss/style.scss';
import FirstSection from './section/FirstSection';
import Header from './components/Header';
import {Footer} from "./section/Footer";

export const App = () => {
  return (
    <div className="labs-wrapper" id="labs.dev">
      <Header />
      <FirstSection />
      <Footer/>
      {/*<LastSection />*/}
    </div>
  );
};
