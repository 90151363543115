import React from 'react';
import logo1 from '../resources/images/logo1.svg'
import logo2 from '../resources/images/logo2.svg'
import logo3 from '../resources/images/logo3.svg'
import logo4 from '../resources/images/logo4.svg'
import logo5 from '../resources/images/logo5.svg'
import logo6 from '../resources/images/logo6.svg'

export const Footer = () => {
    return (
        <div className="footer">
            <div className="footer-container">
                <div className="footer-logos">
                    <div className="item">
                        <img src={logo1} alt=""/>
                    </div>
                    <div className="item">
                        <img src={logo2} alt=""/>
                    </div>
                    <div className="item">
                        <img src={logo3} alt=""/>
                    </div>
                    <div className="item">
                        <img src={logo4} alt=""/>
                    </div>
                    <div className="item">
                        <img src={logo5} alt=""/>
                    </div>
                    <div className="item">
                        <img src={logo6} alt=""/>
                    </div>
                </div>
                <div className="footer-copyright">
                    <p>© 2023 labs.dev</p>
                    <div className="links">
                        {/* <a href="#">facebook</a>
                        <a href="#">twitter</a>
                        <a href="#">github</a> */}
                    </div>
                    <div className="mobile-links">
                        {/* <a href="#">fb</a>
                        <a href="#">tw</a>
                        <a href="#">gh</a> */}
                    </div>
                </div>
            </div>
        </div>
    )
}